<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入任务名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" @sort-change="changeSort" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="taskName" label="任务名称"></el-table-column>
      <el-table-column prop="taskTypeTxt" label="任务类型">
        <template slot-scope="scope">
          <span>{{taskTypesOptions[scope.row.taskType]}}</span>
        </template>
        
      </el-table-column>
      <el-table-column prop="taskLevel" label="任务等级"></el-table-column>
      <el-table-column prop="taskAccept" label="已领取人数"></el-table-column>
      <el-table-column prop="finishCount" label="已完成人数"></el-table-column>
      <el-table-column prop="branchNum" label="分支数量"></el-table-column>
      <el-table-column prop="statusTxt" label="当前状态">
        <!-- <template slot-scope="scope">
          <span v-if="scope.row.status" class="success">已上架</span>
          <span v-else class="warning">已下架</span>
        </template> -->
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="160" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.createTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="160" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.updateTime | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status?'下架':'上架'}}</el-button
          >
          <el-button size="small" type="info" @click="goInfo(scope.row)"
            >详情</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <createTask :dataRow="dataRow" :addVisible="addVisible" @close="addVisible = false" @refresh="getTableList(true)"></createTask>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
import createTask from './createTask'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      taskTypesOptions:{
        '0':'限时任务',
        '1':'常规任务',
        '2':'推广任务',
      },
      searchForm: {
        name:'',
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      dataRow:{}
    };
  },
  components:{pagination,createTask},
  created() {
    this.getTableList();
  },
  methods: {
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
      }
      this.getTableList(true);
    },
    addFunc(){
      this.ruleForm = {};
      this.addVisible = true;
    },
    toEdit(data) {
      this.dataRow = {...data}
      this.addVisible = true;
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.taskList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },

    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskPublish({ id:row.id,status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    goInfo(row){
      this.$router.push({name:'taskListInfo',query:{id:row.id}})
    }
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
